import React from "react";
import Recipe, { CookingStorage } from "./Recipe";
import { BubblesContextStorage, TimersContextStorage } from "./library/context";
import { BubblesDisplay } from "./Bubble";

const RecipeExample = () => {
    const recipe = {
        path: "",
        slug: "",
        description:
            "Croquetas are the perfect appetiser for your party. Easy to prepare in advance, and fry just before you serve them. Forming croquetas ain't easy, but you'll get better the more you practice.",
        awards: [],
        title: "Spanish Ham Croquetas",
        visibility: 200,
        version: "5d26c16c-5959-4236-ae8b-166dd29bde60",
        media_status: "available",
        object: "recipe",
        id: "rec_d5d6fda5ba0d9ffcefa6bb314cbeaa8805978f006e0d956d2145fdf16f46142f",
        profile: {
            name: "Jorge bastida",
            username: "jorgebastida",
            path: "/jorgebastida",
        },
        facts: {
            output: "Serves 8-12",
            output_tokens: [
                { "pos": [0, 7], "text": "Serves ", "type": "text" },
                {
                    "type": "range", "text": "8-12", "value": [
                        { "type": "number", "text": "8", "value": [8], "pos": [7, 8] },
                        { "type": "number", "text": "12", "value": [12], "pos": [9, 11] }
                    ]
                }
            ],
            time: "4h overall including 30m of preping.",
            challenge: "Frying the croquetas without breaking them",
            // storage: "You can freeze the croquetas after you form them for up-to 4 weeks",
        },
        elements: [
            {
                ingredients: [
                    {
                        name: "Flour",
                        quantity: "90 g",
                        quantity_tokens: [
                            { "type": "amount", "text": "90 g", "value": [{ "type": "number", "text": "90", "value": [90], "pos": [0, 2] }, { "text": "g", "value": ["g"] }], "pos": [0, 4] }
                        ]
                    },
                    {
                        name: "Butter",
                        quantity: "90 g",
                        quantity_tokens: [
                            { "type": "amount", "text": "90 g", "value": [{ "type": "number", "text": "90", "value": [90], "pos": [0, 2] }, { "text": "g", "value": ["g"] }], "pos": [0, 4] }
                        ]
                    },
                    {
                        name: "Milk",
                        quantity: "1 litre",
                        quantity_tokens: [
                            { "type": "amount", "text": "1 litre", "value": [{ "type": "number", "text": "1", "value": [1], "pos": [0, 1] }, { "text": "litre", "value": ["l"] }], "pos": [0, 7] }
                        ]
                    },
                    {
                        name: "Small ham dices",
                        quantity: "150 g",
                        quantity_tokens: [
                            { "type": "amount", "text": "150 g", "value": [{ "type": "number", "text": "150", "value": [150], "pos": [0, 3] }, { "text": "g", "value": ["g"] }], "pos": [0, 5] }
                        ]
                    },
                    {
                        name: "Nutmeg",
                        quantity: "1 g",
                        quantity_tokens: [
                            { "type": "amount", "text": "1 g", "value": [{ "type": "number", "text": "1", "value": [1], "pos": [0, 1] }, { "text": "g", "value": ["g"] }], "pos": [0, 3] }
                        ]
                    }
                ],
                "instructions": [
                    {
                        "value": "Melt the butter over medium heat",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    32
                                ],
                                "text": "Melt the butter over medium heat",
                                "type": "text"
                            }
                        ]
                    },
                    {
                        "value": "Add the flour and cook for about two minutes",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    33
                                ],
                                "text": "Add the flour and cook for about ",
                                "type": "text"
                            },
                            {
                                "type": "timer",
                                "text": "two minutes",
                                "value": [
                                    {
                                        "type": "number.text",
                                        "text": "two",
                                        "value": [
                                            "2"
                                        ],
                                        "pos": [
                                            33,
                                            36
                                        ]
                                    },
                                    "m"
                                ],
                                "pos": [
                                    33,
                                    44
                                ]
                            }
                        ]
                    },
                    {
                        "value": "Add the milk in batches. Mix well using a whisk until fully incorporated",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    72
                                ],
                                "text": "Add the milk in batches. Mix well using a whisk until fully incorporated",
                                "type": "text"
                            }
                        ]
                    },
                    {
                        "value": "Add both the ham dices and nutmeg. Mix well",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    43
                                ],
                                "text": "Add both the ham dices and nutmeg. Mix well",
                                "type": "text"
                            }
                        ]
                    },
                    {
                        "value": "Transfer the bechamel to a tray, and let it cool for at least 2-3 hours. You need it to become reasonably solid",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    62
                                ],
                                "text": "Transfer the bechamel to a tray, and let it cool for at least ",
                                "type": "text"
                            },
                            {
                                "type": "timer",
                                "text": "2-3 hours",
                                "value": [
                                    {
                                        "type": "range",
                                        "text": "2-3",
                                        "value": [
                                            {
                                                "type": "number",
                                                "text": "2",
                                                "value": [
                                                    "2"
                                                ],
                                                "pos": [
                                                    62,
                                                    63
                                                ]
                                            },
                                            {
                                                "type": "number",
                                                "text": "3",
                                                "value": [
                                                    "3"
                                                ],
                                                "pos": [
                                                    64,
                                                    65
                                                ]
                                            }
                                        ],
                                        "pos": [
                                            62,
                                            65
                                        ]
                                    },
                                    "h"
                                ],
                                "pos": [
                                    62,
                                    71
                                ]
                            },
                            {
                                "pos": [
                                    71,
                                    111
                                ],
                                "text": ". You need it to become reasonably solid",
                                "type": "text"
                            }
                        ]
                    }
                ]
            },
            {
                ingredients: [
                    {
                        name: "Flour",
                        quantity: "50 g",
                        quantity_tokens: [
                            { "type": "amount", "text": "50 g", "value": [{ "type": "number", "text": "50", "value": [50], "pos": [0, 2] }, { "text": "g", "value": ["g"] }], "pos": [0, 4] }
                        ]
                    },
                    {
                        name: "Breadcrumbs",
                        quantity: "100 g",
                        quantity_tokens: [
                            { "type": "amount", "text": "100 g", "value": [{ "type": "number", "text": "100", "value": [100], "pos": [0, 3] }, { "text": "g", "value": ["g"] }], "pos": [0, 5] }
                        ]
                    },
                    {
                        name: "Egg",
                        quantity: "2",
                        quantity_tokens: [
                            {
                                type: "number",
                                text: "2",
                                value: [2],
                                pos: [
                                    0,
                                    1
                                ]
                            }
                        ]
                    },
                    {
                        name: "Vegetable oil",
                        quantity: "500 ml",
                        quantity_tokens: [
                            { "type": "amount", "text": "500 ml", "value": [{ "type": "number", "text": "500", "value": [500], "pos": [0, 3] }, { "text": "ml", "value": ["ml"] }], "pos": [0, 6] }
                        ]
                    }
                ],
                "instructions": [
                    {
                        "value": "Form the croquetas with the help of some flour. Making small balls of the size of a meatball is the easiest",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    107
                                ],
                                "text": "Form the croquetas with the help of some flour. Making small balls of the size of a meatball is the easiest",
                                "type": "text"
                            }
                        ]
                    },
                    {
                        "value": "Coat them in egg and breadcrumbs",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    32
                                ],
                                "text": "Coat them in egg and breadcrumbs",
                                "type": "text"
                            }
                        ]
                    },
                    {
                        "value": "Fry the croquetas in enough oil so that they are fully covered. Make sure the oil is as hot as possible",
                        "timer": null,
                        "value_tokens": [
                            {
                                "pos": [
                                    0,
                                    103
                                ],
                                "text": "Fry the croquetas in enough oil so that they are fully covered. Make sure the oil is as hot as possible",
                                "type": "text"
                            }
                        ]
                    }
                ]
            }
        ]
    };

    return (
        <BubblesContextStorage initialActiveRecipeId="rec_d5d6fda5ba0d9ffcefa6bb314cbeaa8805978f006e0d956d2145fdf16f46142f">
            <TimersContextStorage>
                <CookingStorage>
                    <Recipe recipe={recipe} cookingEnabled={false} />
                </CookingStorage>
                <BubblesDisplay />
            </TimersContextStorage>
        </BubblesContextStorage>
    );
};

RecipeExample.defaultProps = {};
export default RecipeExample;
