import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Footer } from "../components/Footer";
import { PrimaryButton } from "../components/forms/Button";
import { HeadMeta } from "../components/HeadMeta";
import RecipeExample from "../components/RecipeExample";
import dashboardImage from '../public/landing/dashboard.png';
import flourImage from '../public/landing/flour.jpg';
import jugImage from '../public/landing/jug.jpg';
import recipeBoxImage from '../public/landing/recipebox.jpg';
import Logo from "../public/logoinversename.svg";



const Feature = ({ title, description, children }) => (
    <div className="flex mb-6 max-w-lg">
        <div className="mt-1 bg-gray-200 bg-opacity-75 w-14 h-14 rounded-lg flex justify-center items-center">
            {children}
        </div>
        <div className="flex-1 pl-4">
            <p className="font-bold">{title}</p>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

function HomePage() {
    return (
        <>
            <HeadMeta
                title="Modern publishing platform for cooks"
                description="A small, elegant tool to share your favourite recipes using a consistent and timeless format."
                image="https://fork.club/card.png"
            />
            <div className="container mx-auto max-w-screen-xl mb-20">
                <div className="flex justify-between mt-8 px-4 items-center">
                    <Logo className="w-20 md:w-24 h-auto fill-current text-gray-900 -ml-0 xl:-ml-6" />
                    <div className="flex space-x-10 justify-around items-center ">
                        <a href="/jorgebastida/5b91c94a95f7724e" target="_blank" className="text-gray-500 font-semibold hidden sm:block">
                            Example recipe
                        </a>
                        <Link href="/blog">
                            <a className="text-gray-500 font-semibold hidden sm:block">
                                Blog
                            </a>
                        </Link>
                        <Link href="/login">
                            <a className="text-gray-500 font-semibold">
                                Sign In
                            </a>
                        </Link>
                        <Link href="/join" passHref>
                            <PrimaryButton>Get started</PrimaryButton>
                        </Link>
                    </div>
                </div>

                <div className="grid grid-cols-12 relative overflow-hidden pb-10 md:pb-20">
                    <div className="col-start-2 col-span-10 z-20 pt-20">
                        <h1 className="text-gray-900 font-black text-7xl md:text-10xl tracking-tighter leading-none mb-5 md:mb-10">
                            Recipes,
                            <br />
                            just recipes.
                        </h1>
                        <h2 className="text-gray-900 text-2xl md:text-3xl max-w-md md:max-w-xl leading-tight rounded-lg">
                            A small, elegant tool to collect and share your favourite
                            recipes using a consistent and timeless format.
                        </h2>
                    </div>

                    <div className="border-l border-t border-gray-100 z-20 bg-white shadow-lg px-6 md:px-8 lg:px-12 pt-6 md:pt-10 col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-2 md:col-span-9 mt-12 rounded-2xl pb-8 mx-6 sm:mx-0 md:mx-0">
                        <RecipeExample />
                    </div>

                    <div style={{ width: "600px", height: "600px" }} className="absolute z-10 right-0 top-24 sm:top-14 md:top-32 -mr-48 md:-mr-0 sm:max-w-xl md:max-w-2xl h-auto opacity-25 sm:opacity-50 xl:opacity-75">
                        <Image
                            src={recipeBoxImage}
                            // width={600}
                            // height={600}
                            placeholder="blur"
                            priority
                            alt="Recipe box illustration"
                        />
                    </div>

                    <div className="absolute z-0 polka w-96 h-2/6 right-20 sm:right-0 bottom-0 fill-current" />
                </div>

                <div className="grid grid-cols-12 mt-10 px-4">
                    <div className="col-span-4 md:-mt-10">
                        <Image
                            src={jugImage}
                            width={414}
                            height={414}
                            placeholder="blur"
                            alt="Measuring jug illustration"
                        />
                    </div>

                    <div className="col-span-8 pt-0 pl-4 md:pl-12">
                        <h3 className="text-gray-900 font-black text-4xl md:text-6xl max-w-2xl leading-none tracking-tight mb-2">
                            Fork Club is a modern publishing platform for cooks
                        </h3>
                        <h3 className="text-gray-500 font-bold md:font-black text-xl md:text-3xl leading-tight mb-2">
                            No ads, popups or hideous motivations
                        </h3>
                        <h3 className="text-gray-500 text-lg md:text-2xl leading-tight max-w-xl">
                            Create recipes in minutes, and start sharing them
                            with your friends, family or audience.
                        </h3>
                    </div>
                </div>

                <div className="grid grid-cols-12 mt-10 md:mt-20 px-6">
                    <div className="col-span-12 md:col-span-6">
                        <h4 className="text-gray-900 font-black text-3xl md:text-5xl leading-none tracking-tight mb-2">
                            Waste no time
                        </h4>
                        <h4 className="text-gray-500 text-lg md:text-2xl leading-tight max-w-xl">
                            Our recipe creation tool will help you quickly create and manage your collection.
                        </h4>

                        <div className="mt-8">
                            <Feature
                                title="Consistent structure"
                                description="Collect all your loved recipes in a consistent format."
                            >
                                <svg
                                    className="stroke-current text-gray-700 w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                    />
                                </svg>
                            </Feature>

                            <Feature
                                title="Normalised units"
                                description="Our recipe engine will normalise all your ingredient units automatically. By doing so, you'll be able to scale up and down your recipes every time you cook them."
                            >
                                <svg
                                    className="stroke-current text-gray-700 w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                                    />
                                </svg>
                            </Feature>

                            <Feature
                                title="Multiple instructions groups"
                                description="Split instructions and ingredients into groups so that you reduce the complexity of each of the steps. Your recipes will be much easier to follow!"
                            >
                                <svg
                                    className="stroke-current text-gray-700 w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                                    />
                                </svg>
                            </Feature>

                            <div className="mt-8 flex justify-center md:justify-start">
                                <Link href="/join" passHref>
                                    <PrimaryButton size="xl">
                                        Get started
                                    </PrimaryButton>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="hidden md:block col-span-6 px-8">
                        <div className="h-full w-full">
                            <video
                                autoPlay={true}
                                loop={true}
                                muted={true}
                                playsInline={true}
                                className="border border-gray-100 rounded-xl shadow-2xl"
                            >
                                <source src="/editor.mp4" type="video/mp4" />
                                {/* <source src="homepage-scroll.ogv" type="video/ogg" /> */}
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-12 mt-20 px-6">
                    <div className="hidden md:block col-span-7 pr-20">
                        <Image
                            src={dashboardImage}
                            placeholder="blur"
                            alt="Formats examples"
                            className=""
                        />
                    </div>

                    <div className="col-span-12 md:col-span-5">
                        <h4 className="text-gray-900 font-black text-3xl md:text-5xl leading-none tracking-tight mb-2">
                            Be proud to share
                        </h4>
                        <h4 className="text-gray-500 text-lg md:text-2xl leading-tight max-w-xl">
                            Elevate your recipes and publish them the way they
                            deserve it.
                        </h4>

                        <div className="mt-8">
                            <Feature
                                title="Public links"
                                description="Every recipe has a public link you can immediately share."
                            >
                                <svg
                                    className="stroke-current text-gray-700 w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                                    />
                                </svg>
                            </Feature>

                            <Feature
                                title="Pixel perfect PDF"
                                description={
                                    <span>
                                        We&apos;ll automatically produce a <a className="font-semibold hover:underline" href="https://fork.club/media/print/jorgebastida/5b91c94a95f7724e/40eaa73a-ce81-4f54-a6ba-a56fbc0c2e37.pdf" target="_blank" rel="noreferrer">PDF with your recipe</a>. You can print and collect them, or share them in physical format.
                                    </span>
                                }
                            >
                                <svg
                                    className="stroke-current text-gray-700 w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                    />
                                </svg>
                            </Feature>

                            <Feature
                                title="Public profile"
                                description="All your public recipes are accessible from your profile. Create a beautiful collection of your favourite preparations and inspire others to cook them."
                            >
                                <svg
                                    className="stroke-current text-gray-700 w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                                    />
                                </svg>
                            </Feature>

                            <div className="mt-8 flex justify-center md:justify-start">
                                <Link href="/join" passHref>
                                    <PrimaryButton size="xl">
                                        Get started
                                    </PrimaryButton>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-12 mt-20 px-6">
                    <div className="col-span-12 text-center">
                        <h4 className="text-gray-900 font-black text-3xl md:text-5xl leading-none tracking-tight mb-2">
                            Yes, there&apos;s more...
                        </h4>
                        <h4 className="text-gray-500 text-lg md:text-2xl leading-tight max-w-2xl mx-auto">
                            Developed from the ground up to make sharing and
                            collecting recipes extremely easy.
                        </h4>
                    </div>

                    <div className="col-span-12 md:col-span-6 mt-20 md:pl-10">
                        <Feature
                            title="Exquisite design optimized for cooking"
                            description="Every detail has been carefully thought to improve the experience while cooking. We have removed all the distracting and useless elements so that you'll love to cook using un of our recipes."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                />
                            </svg>
                        </Feature>
                        <Feature
                            title="Cooking tools to make your life easier"
                            description="Improve and speed up your cooking by using our Mise en place and preparation assistant. Scale-up and down your recipes, and make sure you have all the ingredients ready."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                />
                            </svg>
                        </Feature>

                        <Feature
                            title="Sustainable"
                            description="Our business model doesn't rely on Ads, traffic or external investments to keep the lights on, so we'll stay true to our essence."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                                />
                            </svg>
                        </Feature>
                        <Feature
                            title="Run by a human"
                            description="Fork club isn't a team or a startup or a movement or whatever: it's just me. I respond to every email personally and deeply care about the problems I'm trying to solve."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg>
                        </Feature>
                    </div>

                    <div className="col-span-12 md:col-span-6 mt-0 md:mt-20 md:pl-10">
                        <Feature
                            title="Unified collection"
                            description="Instead of keeping notes, Youtube videos and links chaotically organized,  unify your collection and have it always available. Make adjustments and tune your favourite recipes as you cook them."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                />
                            </svg>
                        </Feature>

                        <Feature
                            title="Analytics"
                            description="We ethically source information about which features are used more, but don't share it with anybody."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                />
                            </svg>
                        </Feature>
                        <Feature
                            title="Accessible for everybody"
                            description="Recipes are optimised for accessibility so that everybody will be able to enjoy your creations without trouble from all devices."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                />
                            </svg>
                        </Feature>

                        <Feature
                            title="Blazing fast"
                            description="We are tech nerds. We have improved every possible detail to make this tool as fast as possible — No more slow loading pages, broken links or lost recipes."
                        >
                            <svg
                                className="stroke-current text-gray-700 w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M13 10V3L4 14h7v7l9-11h-7z"
                                />
                            </svg>
                        </Feature>
                    </div>
                </div>

                <div className="grid grid-cols-12 mt-10 md:mt-20 px-6">
                    <div className="col-span-12 md:col-span-6">
                        <h4 className="text-gray-900 font-black text-3xl md:text-5xl leading-none tracking-tight mb-2">
                            Opinionated
                        </h4>
                        <h4 className="text-gray-500 text-lg md:text-2xl leading-tight max-w-xl">
                            We have a different approach, and we know it.
                        </h4>

                        <div className="mt-8">
                            <Image
                                src={flourImage}
                                width={424}
                                height={330}
                                placeholder="blur"
                                alt="Measuring spoon full of flour illustration"
                            />
                        </div>
                    </div>

                    <div className="col-span-12 md:col-span-6 grid-cols-12 grid gap-6">
                        <div className="col-span-6">
                            <p className="font-bold">Concise</p>
                            <p className="text-gray-600">
                                We enforce concise descriptions and instructions
                                to make readers enjoy your recipes. Recipes must
                                be as brief as possible to not make them get in
                                between you and your cooking. The less, the
                                better.
                            </p>
                        </div>
                        <div className="col-span-6">
                            <p className="font-bold">No difficulty</p>
                            <p className="text-gray-600">
                                Recipe difficulties are just gatekeeping, which
                                dissuades people from cooking. Instead, we let
                                you define which part of the recipe is the
                                hardest and let the reader decide to cook the
                                recipe or not.
                            </p>
                        </div>
                        <div className="col-span-6">
                            <p className="font-bold">No images</p>
                            <p className="text-gray-600">
                                A bad recipe with a good image is a trap, a good
                                recipe with a bad image is a missed opportunity.
                            </p>
                        </div>
                        <div className="col-span-6">
                            <p className="font-bold">Not a blog</p>
                            <p className="text-gray-600">
                                Recipes are timeless. A recently posted recipe
                                ain&apos;t better than an old one.
                            </p>
                        </div>
                        <div className="col-span-6">
                            <p className="font-bold">No ads</p>
                            <p className="text-gray-600">
                                Our business model is not to sell your recipes
                                traffic for ads. Prolific authors pay a small
                                fee to store their recipes.
                            </p>
                        </div>
                        <div className="col-span-6">
                            <p className="font-bold">No tracking</p>
                            <p className="text-gray-600">
                                We don&apos;t directly/indirectly share your
                                behaviour to companies like Google or Facebook.
                                What happens in your kitchen stays in your
                                kitchen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default HomePage;
